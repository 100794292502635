import * as React from "react"
import Layout from "../components/layout"
// import JobPostsFilter from "../components/jobPostsFilter"
import JobPosts from "../components/jobPosts"
import Seo from "../components/seo"
import JobsNav from "../components/jobsNav"

/*

Categories:

chefs = 2
front-of-house = 9
sales-marketing = 11
operations = 13
international = 4 


*/

const Vacancies = () => (
  <Layout jobPage={true} splashPage={false} pageTitle="VACANCIES">
    <Seo title="Vacancies" />
    <JobsNav />
    <JobPosts />
  </Layout>
)

export default Vacancies
